import fetchAllCurrencies from '@lyra/core/api/fetchAllCurrencies'
import fetchStatistics from '@lyra/core/api/fetchStatistics'
import { PublicStatisticsResponseSchema } from '@lyra/core/api/types/public.statistics'
import useSWR from 'swr'

import { InstrumentType } from '../constants/instruments'
import { MarketId } from '../constants/markets'
import { CollateralId, CurrencyId } from '../constants/tokens'
import {
  getActiveMarketListMarketIds,
  getActiveOptionMarketIds,
  getActivePerpMarketIds,
  getActiveSpotCollateralIds,
  getCollateralId,
  getMarketId,
} from '../utils/markets'

export type InstrumentStats = {
  volume24h: number
  trades24h: number
  openInterest: number
  openInterestDollars: number
  tradesTotal: number
  volumeTotal: number
}

const EMTPY_INSTRUMENT_STATS: InstrumentStats = {
  volume24h: 0,
  trades24h: 0,
  openInterest: 0,
  openInterestDollars: 0,
  tradesTotal: 0,
  volumeTotal: 0,
}

export type MarketStats = {
  volume24h: number
  trades24h: number
  openInterest: number
  openInterestDollars: number
  tradesTotal: number
  volumeTotal: number
  instruments: Record<InstrumentType, InstrumentStats>
}

export type TotalStats = {
  volume24h: number
  trades24h: number
  openInterestDollars: number
  tradesTotal: number
  volumeTotal: number
  markets: Record<CurrencyId, MarketStats>
}

const fetcher = async (): Promise<TotalStats> => {
  const optionMarketIds = getActiveOptionMarketIds()
  const perpMarketIds = getActivePerpMarketIds()
  const spotCollateralIds = getActiveSpotCollateralIds()

  // Get all active markets
  // Filter the active perp / option markets against what is activated to show
  // Ensures the enabled list of markets are also enabled perp / option markets
  const showInMarketListMarkets = getActiveMarketListMarketIds()
  const filteredOptionMarketIds = optionMarketIds.filter((market) =>
    showInMarketListMarkets.includes(market)
  )
  const filteredPerpMarketIds = perpMarketIds.filter((market) =>
    showInMarketListMarkets.includes(market)
  )

  const [{ result: currencies }, optionMarketStats, perpMarketStats, spotMarketStats] =
    await Promise.all([
      fetchAllCurrencies(),
      Promise.all(
        filteredOptionMarketIds.map((marketId) =>
          fetchStatistics({ currency: marketId, instrument_name: 'OPTION' })
        )
      ),
      Promise.all(
        filteredPerpMarketIds.map((marketId) =>
          fetchStatistics({ currency: marketId, instrument_name: 'PERP' })
        )
      ),
      Promise.all(
        spotCollateralIds.map((collateralId) =>
          fetchStatistics({ currency: collateralId, instrument_name: 'SPOT' })
        )
      ),
    ])

  const optionMarketStatsByMarket: Record<MarketId, PublicStatisticsResponseSchema | undefined> =
    optionMarketStats.reduce(
      (dict, stats, idx) => ({
        ...dict,
        [optionMarketIds[idx]]: stats,
      }),
      {} as Record<MarketId, PublicStatisticsResponseSchema | undefined>
    )

  const perpMarketStatsByMarket: Record<MarketId, PublicStatisticsResponseSchema | undefined> =
    perpMarketStats.reduce(
      (dict, stats, idx) => ({
        ...dict,
        [perpMarketIds[idx]]: stats,
      }),
      {} as Record<MarketId, PublicStatisticsResponseSchema | undefined>
    )

  const spotMarketStatsByMarket: Record<CollateralId, PublicStatisticsResponseSchema | undefined> =
    spotMarketStats.reduce(
      (dict, stats, idx) => ({
        ...dict,
        [spotCollateralIds[idx]]: stats,
      }),
      {} as Record<CollateralId, PublicStatisticsResponseSchema | undefined>
    )

  const spotPriceByMarket: Record<CurrencyId, number> = Object.values(currencies).reduce(
    (dict, currency) => {
      const market = getMarketId(currency.currency)
      if (market) {
        return {
          ...dict,
          [market]: +currency.spot_price,
        }
      }

      const collateral = getCollateralId(currency.currency)
      if (collateral) {
        return {
          ...dict,
          [collateral]: +currency.spot_price,
        }
      }

      return dict
    },
    {} as Record<CurrencyId, number>
  )

  const marketIds = Object.values(MarketId)
  const collateralIds = Object.values(CollateralId)
  const allIds: Array<CurrencyId> = [...marketIds, ...collateralIds]

  const marketStatsByMarket: Record<CurrencyId, MarketStats> = allIds.reduce(
    (dict, marketId) => {
      const marketOptionStats = optionMarketStatsByMarket[marketId as MarketId]?.result
      const marketPerpStats = perpMarketStatsByMarket[marketId as MarketId]?.result
      const marketSpotStats = spotMarketStatsByMarket[marketId as CollateralId]?.result

      const spotPrice = spotPriceByMarket[marketId as MarketId] ?? 0

      const optionStats: InstrumentStats = marketOptionStats
        ? {
            volume24h: +marketOptionStats.daily_notional_volume,
            trades24h: marketOptionStats.daily_trades,
            openInterest: +marketOptionStats.open_interest,
            openInterestDollars: +marketOptionStats.open_interest * spotPrice,
            volumeTotal: +marketOptionStats.total_notional_volume,
            tradesTotal: marketOptionStats.total_trades,
          }
        : EMTPY_INSTRUMENT_STATS

      const perpStats: InstrumentStats = marketPerpStats
        ? {
            volume24h: +marketPerpStats.daily_notional_volume,
            trades24h: marketPerpStats.daily_trades,
            openInterest: +marketPerpStats.open_interest,
            openInterestDollars: +marketPerpStats.open_interest * spotPrice,
            volumeTotal: +marketPerpStats.total_notional_volume,
            tradesTotal: marketPerpStats.total_trades,
          }
        : EMTPY_INSTRUMENT_STATS

      const spotStats: InstrumentStats = marketSpotStats
        ? {
            volume24h: +marketSpotStats.daily_notional_volume,
            trades24h: marketSpotStats.daily_trades,
            openInterest: +marketSpotStats.open_interest,
            openInterestDollars: +marketSpotStats.open_interest * spotPrice,
            volumeTotal: +marketSpotStats.total_notional_volume,
            tradesTotal: marketSpotStats.total_trades,
          }
        : EMTPY_INSTRUMENT_STATS

      const marketStats: MarketStats = {
        volume24h: optionStats.volume24h + perpStats.volume24h,
        trades24h: optionStats.trades24h + perpStats.trades24h,
        openInterest: optionStats.openInterest + perpStats.openInterest,
        openInterestDollars: optionStats.openInterestDollars + perpStats.openInterestDollars,
        volumeTotal: optionStats.volumeTotal + perpStats.volumeTotal,
        tradesTotal: optionStats.tradesTotal + perpStats.tradesTotal,
        instruments: {
          [InstrumentType.Options]: optionStats,
          [InstrumentType.Perps]: perpStats,
          [InstrumentType.Spot]: spotStats,
        },
      }

      return {
        ...dict,
        [marketId]: marketStats,
      }
    },
    {} as Record<CurrencyId, MarketStats>
  )

  return {
    volume24h: Object.values(marketStatsByMarket).reduce((sum, stats) => sum + stats.volume24h, 0),
    trades24h: Object.values(marketStatsByMarket).reduce((sum, stats) => sum + stats.trades24h, 0),
    openInterestDollars: Object.values(marketStatsByMarket).reduce(
      (sum, stats) => sum + stats.openInterestDollars,
      0
    ),
    volumeTotal: Object.values(marketStatsByMarket).reduce(
      (sum, stats) => sum + stats.volumeTotal,
      0
    ),
    tradesTotal: Object.values(marketStatsByMarket).reduce(
      (sum, stats) => sum + stats.tradesTotal,
      0
    ),
    markets: marketStatsByMarket,
  }
}

export default function useStats() {
  return useSWR<TotalStats>(['ExchangeStats'], fetcher, {
    keepPreviousData: true,
    revalidateOnFocus: false,
  })
}
